import { Observable, of, throwError } from 'rxjs';
import { concatMap, delay, retryWhen } from 'rxjs/operators';

const DEFAULT_MAX_RETRIES = 20;
const DEFAULT_MAX_DELAY = 120000;

export function retryHttp(
  initialDelay = 500,
  maxRetry = DEFAULT_MAX_RETRIES,
  maxDelay = DEFAULT_MAX_DELAY,
) {
  let retries = maxRetry;
  let currentDelay = initialDelay / 2.0;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (src: Observable<any>) => {
    return src.pipe(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      retryWhen((errors: Observable<any>) =>
        errors.pipe(
          concatMap((error) => {
            if (retries-- > 0) {
              // Expontionally increase the retry delay until maxDelay
              currentDelay = currentDelay * 2;
              if (currentDelay > maxDelay) {
                currentDelay = maxDelay;
              }
              console.warn(
                `Connection attempt failed.  Sleeping ${currentDelay / 1000}s before retrying ...`,
              );
              return of(error).pipe(delay(currentDelay));
            }

            console.error(`Maximum retries (${maxRetry}) exceeded`);
            return throwError(error);
          }),
        ),
      ),
    );
  };
}
