import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { retryHttp } from '@app/shared/utils/retry-http';
import { CognitoUser } from '@cco/model';
import { Store } from '@ngrx/store';
import { RxStomp, RxStompConfig, RxStompState } from '@stomp/rx-stomp';
import { combineLatest } from 'rxjs';
import { catchError, distinctUntilChanged, filter, shareReplay, takeUntil, tap } from 'rxjs/operators';
import * as Sentry from '@sentry/angular';
import { APP_CONFIG, AppConfig } from '@cco/apps/cco-frontend';
import { CsrfResponse } from '@core/xsrf/xsrf.interceptor';
import { userTenantFeature } from '@core/user-tenant/user-tenant.reducer';
import { notNullish } from '@app/shared/rxjs/operators/standard-operators';

@Injectable()
export class CgccStompService extends RxStomp {
  constructor(
    @Inject(APP_CONFIG) private appConfig: AppConfig,
    private store: Store,
    private http: HttpClient,
  ) {
    super();

    this.store.select(userTenantFeature.selectSelectedTenantId).pipe(
      notNullish(),
      distinctUntilChanged(),
    ).subscribe(async (tenantId) => {
        const config: RxStompConfig | undefined = this.getConfig(tenantId);
        if (config == null)
          return;

        config.beforeConnect = () => new Promise<void>((resolve, reject) => {
          combineLatest([
            this.http
              .get<CognitoUser>(`${appConfig.apiBaseUrl}/security/users/current`)
              .pipe(
                retryHttp(500),
                takeUntil(
                  this.connectionState$.pipe(
                    filter(connectionState => connectionState === RxStompState.CLOSED),
                  ),
                ),
                catchError((error) => {
                  console.error(
                    'Failed to get a successful response, you should not initiate a WebSocket connection',
                    error,
                  );

                  throw error;
                }),
                shareReplay(),
              ),
            this.http.get<CsrfResponse>(`${appConfig.apiBaseUrl}/security/csrf`).pipe(
              retryHttp(500),
              takeUntil(
                this.connectionState$.pipe(
                  filter(connectionState => connectionState === RxStompState.CLOSED),
                ),
              ),
              catchError((error) => {
                console.error(
                  'Failed to get a successful response, you should not initiate a WebSocket connection',
                  error,
                );

                throw error;
              }),
              tap((csrfResponse) => {
                config.connectHeaders[csrfResponse.headerName] = csrfResponse.token;
              }),
            )
          ]).subscribe({
            next: () => resolve(),
            error: () => reject(),
          });
        });

        this.configure(config);
        this.activate();
      });
  }

  private getConfig(tenantId: string) {
    try {
      return {
        brokerURL: this.getTenantBrokerUrl(tenantId),
        connectHeaders: {
          login: 'guest',
          passcode: 'guest',
        },
        heartbeatIncoming: 0,
        heartbeatOutgoing: 20000,
        reconnectDelay: 500,
      };
    } catch (e) {
      Sentry.captureException(e);
    }
  }

  private getTenantBrokerUrl(tenantId: string) {
    const url = new URL(this.appConfig.apiBaseUrl, location.origin);
    if (url.protocol === 'https:')
      url.protocol = 'wss:';
    else if (url.protocol === 'http:')
      url.protocol = 'ws:';
    else
      throw new Error('Unhandled URL scheme: ' + url.protocol);
    url.pathname = `${url.pathname}/o/${tenantId}/ws`;

    return url.toString();
  }

  publishWithoutLocalQueuing(queueName: string, message?: string, headers = {}): boolean {
    if (this.connected()) {
      this.stompClient.publish({ destination: queueName, body: message, headers });

      return true;
    }

    return false;
  }
}
