import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { StandardService } from '@app/shared/services/standard.service';
import { augmentWithMilliTimestamp, MultiGpsMessage, RawGpsMessage, Truck } from '@cco/model';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { APP_CONFIG, AppConfig } from '@cco/apps/cco-frontend';

@Injectable()
export class TruckService extends StandardService<Truck, number> {
  constructor(@Inject(APP_CONFIG) config: AppConfig, protected http: HttpClient) {
    super(http, config.apiBaseUrl, `/trucks`);
  }

  getTrucksLatestGps$(): Observable<MultiGpsMessage> {
    return this.tenantBaseUrl$.pipe(
      switchMap(baseUrl => this.http.get<MultiGpsMessage>(`${baseUrl + this.relativeUrl}/gps/latest`).pipe(
        map(response => this.mapMultiGpsMessage.bind(this)(response)),
      )),
    );
  }

  getTruckGps$(
    truckId: number,
    start: string,
    end: string,
    aggregation?: 'minute' | null,
  ): Observable<RawGpsMessage[]> {
    return this.tenantBaseUrl$.pipe(
      switchMap(baseUrl => this.http
      .get<RawGpsMessage[]>(`${baseUrl + this.relativeUrl}/${truckId}/gps`, {
        params: { start, end, aggregation },
      })
      .pipe(map(response => this.mapRawGpsMessages.bind(this)(response)))));
  }

  mapMultiGpsMessage(message: MultiGpsMessage): MultiGpsMessage {
    const augmented = {
      assets: message.assets,
    };
    for (const assetUuid in augmented.assets) {
      augmented.assets[assetUuid] = augmentWithMilliTimestamp(augmented.assets[assetUuid]);
    }

    return augmented;
  }

  mapRawGpsMessages(messages: RawGpsMessage[]): RawGpsMessage[] {
    return messages.map(m => augmentWithMilliTimestamp(m));
  }
}
