import { DateTime } from 'luxon';
import { TimeSeriesData } from './time-series-data';

export interface RawGpsMessage extends TimeSeriesData {
  timestamp: number;
  altitude?: number;
  trackAngle?: number;
  magneticVariation?: number;
  groundSpeed?: number;
  lat: number;
  lng: number;
  truckUuid?: string;
  status?: string;
  fixType?: string;
  fixQuality?: number;
  serialNumber?: string;
  horizontalDilution?: number;
  satellitesTracked?: number;
  geoidHeight?: number;
}

export interface MultiGpsMessage {
  assets: {
    [assetUuid: string]: RawGpsMessage;
  };
}

export function augmentWithMilliTimestamp(raw: RawGpsMessage): RawGpsMessage {
  if (raw.milliTimestamp) {
    return raw;
  }

  let milliTimestamp: number;
  if (typeof raw.timestamp === 'number') {
    milliTimestamp = raw.timestamp * 1000;
  } else if (typeof raw.timestamp === 'string') {
    milliTimestamp = DateTime.fromISO(raw.timestamp).valueOf();
  } else {
    throw new Error('Unhandled timestamp format:' + raw.timestamp);
  }

  return { ...raw, milliTimestamp };
}
