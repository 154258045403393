import { createApiActions } from '@app/shared/store/standard.actions';
import { NotificationMessage, NotificationTarget } from '@cco/model';
import { props } from '@ngrx/store';

export const {
  baseAction: sendNotification,
  successAction: sendNotificationSuccess,
  failureAction: sendNotificationFailure,
} = createApiActions(
  '[Notification] Send Notification',
  props<{ requestId?: string; target: NotificationTarget; message: NotificationMessage }>(),
  props<{ requestId?: string }>(),
);
